import React, {useState,useEffect} from 'react'
import { useParams } from 'react-router-dom';
import ArrowIcon from '../assets/svgs/Group 3.svg';
import GradientArrowIcon from '../assets/svgs/Group 3 (5).svg';
import '../components-css/single-casestudy-page.scss'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from "react-router-dom";
import projectImg from '../pngs/Group 672.png';
import clientImg from '../pngs/Ellipse 27 (1).png'
import axios from 'axios';
import { BASE_URL } from '../config';
import { useNavigate } from 'react-router-dom';

const SingleCasestudyPage = () => {
    const navigate = useNavigate();
    const [Casestudy, setCasestudy] = useState(false);
    const { id } = useParams();

    useEffect(()=> {
      getcasestudyData()
    }, [])

    const getcasestudyData = async () => {
        try {
            let payload = {
                id: id
            }
          let response = await axios.post(`${BASE_URL}/casestudyDetails`,payload)
          setCasestudy(response.data.data)
          console.log('respone about us page', response.data.data)
        } catch (error) {
          console.log(error)
        }
      }

    const settings = {
        infinite: true,
        speed: 500,
        wheelControl: false,
        trimWhiteSpace: true,
        slidesToScroll: 1,
        autoplay: true, // Enable autoplay
        autoplaySpeed: 2000,
        arrows: false,
        slidesToShow: 5,
        responsive: [
            {
                breakpoint: 1024, // Tablet breakpoint
                settings: {
                    slidesToShow: 4.1, // Show 3 slides on tablet
                },
            },
            {
                breakpoint: 767, // Phone breakpoint
                settings: {
                    slidesToShow: 2, // Show 2 slides on phone
                },
            },
        ],
    };

    const projectData = [
        {
            title: 'Test Project 1',
            image: projectImg
        },
        {
            title: 'Test Project 2',
            image: projectImg
        },
        {
            title: 'Test Project 3',
            image: projectImg
        },
        {
            title: 'Test Project 4',
            image: projectImg
        },
        {
            title: 'Test Project 5',
            image: projectImg
        },
        {
            title: 'Test Project 6',
            image: projectImg
        },
        {
            title: 'Test Project 7',
            image: projectImg
        }
    ];

    const [isHovered, setIsHovered] = useState(false);
    const projectProblemData = [
        {
            title: 'Complex user interface',
            description: 'Many live GPS tracking mobile apps suffer from a complex and cluttered user interface. Users often find it challenging to navigate through various features, settings, and maps, which can lead to a frustrating user experience.'
        },
        {
            title: 'Complex user interface',
            description: 'Many live GPS tracking mobile apps suffer from a complex and cluttered user interface. Users often find it challenging to navigate through various features, settings, and maps, which can lead to a frustrating user experience.'
        },
        {
            title: 'Complex user interface',
            description: 'Many live GPS tracking mobile apps suffer from a complex and cluttered user interface. Users often find it challenging to navigate through various features, settings, and maps, which can lead to a frustrating user experience.'
        },
        {
            title: 'Complex user interface',
            description: 'Many live GPS tracking mobile apps suffer from a complex and cluttered user interface. Users often find it challenging to navigate through various features, settings, and maps, which can lead to a frustrating user experience.'
        }
    ]
    const projectSolutionData = [
        {
            title: 'Complex user interface',
            description: 'Many live GPS tracking mobile apps suffer from a complex and cluttered user interface. Users often find it challenging to navigate through various features, settings, and maps, which can lead to a frustrating user experience.'
        },
        {
            title: 'Complex user interface',
            description: 'Many live GPS tracking mobile apps suffer from a complex and cluttered user interface. Users often find it challenging to navigate through various features, settings, and maps, which can lead to a frustrating user experience.'
        },
        {
            title: 'Complex user interface',
            description: 'Many live GPS tracking mobile apps suffer from a complex and cluttered user interface. Users often find it challenging to navigate through various features, settings, and maps, which can lead to a frustrating user experience.'
        },
        {
            title: 'Complex user interface',
            description: 'Many live GPS tracking mobile apps suffer from a complex and cluttered user interface. Users often find it challenging to navigate through various features, settings, and maps, which can lead to a frustrating user experience.'
        }
    ]


    
    return (
        <div className="single-case-study-container">
            <div className="banner" style={{ backgroundImage: `url('${Casestudy?.banner_path}${Casestudy?.banner}')`}}>
                <h1>{Casestudy?.title}</h1>
                <p>{Casestudy?.sub_title}</p>
            </div>
            <div className="about-project-case-study" style={{ backgroundImage: `url(${Casestudy?.client_image_path}${Casestudy?.about_background_image})`}}>
                <div className="about-left">
                    <h2>ABOUT <span>{Casestudy?.title}</span></h2>
                    <p>{Casestudy?.about}</p>
                </div>
                <div className="about-right">
                    <h4>INDUSTRY</h4>
                    <span>{Casestudy?.industry}</span>
                    <h4>Technologies</h4>
                    <span>{Casestudy?.technology}</span>
                    <h4>SERVICE</h4>
                    <span>{Casestudy?.service}</span>
                </div>
            </div>
            { Casestudy?.project_solution !== null && Casestudy?.project_problem !== null && (
                <div className="project-problem-solution">
                    <div className="project-problem">
                        <h2>PROJECT PROBLEM</h2>
                        <p dangerouslySetInnerHTML={{ __html: Casestudy?.project_problem }} />
                    </div>
                    <div className="project-solution">
                        <h2>PROJECT SOLUTION</h2>
                        <p dangerouslySetInnerHTML={{ __html: Casestudy?.project_solution }} />
                    </div>
                </div>
            )}

            <div className="project-showcase">
                <div className="project-slider">
                    <Slider {...settings}>
                        {Casestudy?.productimages?.map((project, index) => (
                            <div className='width' key={index}>
                                {/* <p>{project.title}</p> */}
                                <img src={`${project?.path}${project?.image}`} alt={project.title} />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
            <div className="project-client">
                <div className="bg-color">
                    <div className="about-client">
                        <img src={`${Casestudy?.client_image_path}${Casestudy?.client_image}`} alt="" />
                        <h5>{Casestudy?.client_name}</h5>
                        <span>{Casestudy?.client_designation}</span>
                    </div>
                    <div className="client-review">
                        <p>{Casestudy?.review}</p>
                        {/* <div className="button">
                            <button onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}>
                                <Link className='portfolio-page'><span>VIEW CASE STUDY</span></Link><span className='img'>
                                    {isHovered ? (
                                        <img src={GradientArrowIcon} alt="gradient-icon" />
                                    ) : (
                                        <img src={ArrowIcon} alt="arrow-icon" />
                                    )}
                                </span>
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="start-your-project">
                <h2>Embark on Your Dream Project Journey With Us</h2>
                <p>We provide exceptional, user-friendly healthcare solutions that improve patient care and ensure data privacy. Allow us to turn your vision into reality with our premier healthcare app development services.</p>
                <div className="button">
                    <button 
                        onClick={() => { navigate(`/contact-us`) }}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}>
                        <Link className='portfolio-page' to="/contact-us"><span>START YOUR PROJECT TODAY</span></Link><span className='img'>
                            {isHovered ? (
                                <img src={GradientArrowIcon} alt="gradient-icon" />
                            ) : (
                                <img src={ArrowIcon} alt="arrow-icon" />
                            )}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SingleCasestudyPage