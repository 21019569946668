import React, { useEffect, useState } from 'react';
import latestBlogImg from '../pngs/Group 499.png';
import iconImg from '../assets/svgs/right-white.svg';
import searchImg from '../assets/svgs/search.svg';
import iconImgBlack from '../assets/svgs/right-black.svg';
import Loader from '../components/loader';
import '../components-css/blogs-page.scss';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../config';

const BlogsPage = () => {
    const [loading, setLoading] = useState(true);
    const [blogsData, setBlogsData] = useState(null);
    const [searchQuery, setSearchQuery] = useState(""); // Added state for search query
    const navigate = useNavigate();
    
    useEffect(() => {
        const blogData = async () => {
            try {
                let response = await axios.get(`${BASE_URL}/GetBlogs`);
                setBlogsData(response.data.data);
                console.log('checking', response);
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        };
        
        blogData();
    }, []);
    
    const getPlainText = (html) => {
        if (!html) return "";
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = html;
        return tempDiv.textContent || tempDiv.innerText || "";
    };

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };

    const filteredBlogs = blogsData?.blog_list?.filter(blog =>
        blog.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className='blog-page-main-conatiner'>
            {
                loading ? (<Loader />) : (
                    <>
                        <div className="latest-blog" key={blogsData?.first_blog[0]?.id} onClick={() => navigate(`/blog/${blogsData?.first_blog[0]?.id}`, { state: blogsData?.first_blog[0]?.id })}>
                            <div className="left">
                                <img src={blogsData?.first_blog[0]?.file_path + blogsData?.first_blog[0]?.thumbnail} alt="latest-blog-image" />
                            </div>
                            <div className="right">
                                <div className="icon">
                                    <span><img src={iconImg} alt="icon-image" /></span>
                                </div>
                                <h2>{blogsData?.first_blog[0]?.title}</h2>
                                <p>{getPlainText(blogsData?.first_blog[0]?.description).slice(0, 160) + "..."}</p>
                                <span>{formatDate(blogsData?.first_blog[0]?.created_at)}</span>
                            </div>
                        </div>
                        <div className="blogs-section">
                            <div className="input">
                                <div className="search-box">
                                    <img src={searchImg} alt="icon-image" />
                                    <input 
                                        type="text" 
                                        placeholder="Search"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="blogs-cards-section">
                                {
                                    filteredBlogs?.length > 0 ? filteredBlogs.map((blog) => (
                                        <div key={blog.id} onClick={() => navigate(`/blog/${blog.id}`, { state: blog.id })} className="blog-card" style={{ backgroundImage: `url('${blog?.file_path}${blog?.thumbnail}')` }}>
                                            <span><img src={iconImgBlack} alt="icon-image" /></span>
                                            <h3>{blog.title}</h3>
                                        </div>
                                    )) : <p>No blogs found</p>
                                }
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    );
};

export default BlogsPage;
