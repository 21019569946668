import React, { useState,useEffect } from 'react'
import { useParams } from 'react-router-dom';
import ArrowIcon from '../assets/svgs/Group 3.svg';
import ArrowIconwhite from '../assets/svgs/Group 3 (6).svg';
import GradientArrowIcon from '../assets/svgs/Group 3 (5).svg';
import { Link } from "react-router-dom";
import '../components-css/single-portfolio-page.scss';
import bannerRightImg from '../pngs/Group 716.png';
import projectImg from '../pngs/Group 672.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import clientImg from '../pngs/Ellipse 27 (1).png'
import axios from 'axios';
import { BASE_URL } from '../config';
import PortfolioPage from './PortfolioPage';
import { useNavigate } from 'react-router-dom';

const SinglePortfolioPage = () => {
    const { id } = useParams();
    const [porfolio, setPorfolio] = useState(false);
    
    useEffect(()=> {
        getPortfolioPageData()
    }, [])
    
    const getPortfolioPageData = async () => {
        try {
            let payload = {
                id: id
            }
            let response = await axios.post(`${BASE_URL}/portfolioDetails`, payload)
            setPorfolio(response.data.data)
            console.log('service page response', response)
        } catch (error) {
            console.log(error)
        }
    }


    const settings = {
        infinite: true,
        speed: 500,
        wheelControl: false,
        trimWhiteSpace: true,
        slidesToScroll: 1,
        autoplay: true, // Enable autoplay
        autoplaySpeed: 2000,
        arrows: false,
        slidesToShow: 6,
        responsive: [
            {
                breakpoint: 1024, // Tablet breakpoint
                settings: {
                    slidesToShow: 3.1, // Show 3 slides on tablet
                },
            },
            {
                breakpoint: 767, // Phone breakpoint
                settings: {
                    slidesToShow: 2, // Show 2 slides on phone
                },
            },
        ],
    };

    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate()
    
    return (
        <div className="single-portfolio-container">
            <div className="banner" style={{ backgroundImage: `url('${porfolio?.slider_image_path}${porfolio?.slider_background_image}')`}}>
                <div className="banner-left">
                    
                    { porfolio?.project_type !== "branding" && (
                        <>
                        <h1>{porfolio?.title}</h1>
                        <h3>{porfolio?.sub_title}</h3>
                        {porfolio?.case_study_link !== null && (
                            <div className="button" >
                                <button
                                    onClick={() => { navigate(`/case-study/${porfolio?.case_study_link}`) }}
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                >
                                    <Link className='portfolio-page'>
                                        <span>View case study</span>
                                    </Link>
                                    <span className='img'>
                                        {isHovered ? (
                                            <img src={GradientArrowIcon} alt="gradient-icon" />
                                        ) : (
                                            <img src={ArrowIcon} alt="arrow-icon" />
                                        )}
                                    </span>
                                </button>
                            </div>
                        )}
                    </>
                    )}
                </div>
                <div className="banner-right">
                    {/* <div className='image' ></div> */}
                    <img src={porfolio?.slider_image_path + porfolio?.slider_image} alt="Banner Right" />
                </div>
                { porfolio?.case_study_link !== null && (
                    <>
                    <div className="button d-none">
                        <button
                            onClick={() => { navigate(`/case-study/${porfolio?.case_study_link}`) }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            <Link className='portfolio-page'>
                                <span>View case study</span>
                            </Link>
                            <span className='img'>
                                {isHovered ? (
                                    <img src={GradientArrowIcon} alt="gradient-icon" />
                                ) : (
                                    <img src={ArrowIcon} alt="arrow-icon" />
                                )}
                            </span>
                        </button>
                    </div>
                    </>
                )}
            </div>
            <div className="project-overview" style={{ backgroundImage: `url(${porfolio?.slider_image_path}${porfolio?.project_description_background_image})`}}>
                <h4>Project Overview</h4>
                <hr />
                <p>{porfolio?.project_description}</p>
            </div>

            {porfolio?.logo_making_image !== null && (
                <div className='logo-image'>
                    <img src={`${porfolio?.common_path}${porfolio?.logo_making_image}`}></img>
                </div>
            )}
            { porfolio?.is_lables === 1 && (
                <div className="project-lables">
                    <h4>Lables</h4>
                    <hr />
                    <div className='image-grid'>
                        {porfolio?.lable.map((lable,index) => (
                            <img src={`${lable.path}${lable.image}`}></img>
                        ))}
                    </div>
                </div>
            )}

            { porfolio?.is_social_media_post === 1 && (
                <div className="project-social-media-post">
                    <h4>Social Media Post</h4>
                    <hr/>
                    <div className='image-grid'>
                        {porfolio?.socialmediapost.map((socialmediapost,index) => (
                            <img src={`${socialmediapost.path}${socialmediapost.image}`}></img>
                        ))}
                    </div>
                </div>
            )}

            { porfolio?.is_logo_has_concept === 1 && (
                <div className='concept'>
                        <div className='concept-img'>
                            <img src={ `${porfolio?.common_path}${porfolio?.logo_concept_image}` }></img>
                        </div>
                        <div className='concept-text'>
                            <h4>Logo Concept</h4>
                            <hr></hr>
                            <p>{porfolio?.logo_concept}</p>
                        </div>
                </div>
            )}


            { porfolio?.productimages && (
                 porfolio?.project_type == "wordpress" ? (
                    <div className='project-image'>
                        <h4>Project Showcase</h4>
                        <hr></hr>
                        <div className="project-images-scroll">
                            <div className='image-scroll'>
                                <img src={`${porfolio?.productimages[0].path}${porfolio?.productimages[0].image}`}></img>
                            </div>
                        </div>
                    </div>
                ) : porfolio?.project_type == "branding" ? (
                    <div className="project-images">
                        <div className='image-grid'>
                            {porfolio?.productimages.map((productimages,index) => (
                                <img src={`${productimages.path}${productimages.image}`}></img>
                            ))}
                        </div>
                    </div>
                ) : null
            )}


            {porfolio?.project_type === 'mobile-apps' && (
                <div className="project-showcase">
                    <h4>Project Showcase</h4>
                    <hr />
                    <div className="project-slider">
                        <Slider {...settings}>
                            {porfolio?.productimages.map((project, index) => (
                                <div className='width' key={index}>
                                    <p>{project.title}</p>
                                    <img src={ `${project.path}${project.image}`} alt={project.title} />
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            )}

            { porfolio?.is_client === 1 && (

                <div className="project-client">
                    <div className="bg-color">
                        <div className="about-client">
                            <img src={`${porfolio?.client_path}${porfolio?.client_image}`} alt="" />
                            <h5>{porfolio?.client_name?.toUpperCase()}</h5>
                            <span>{porfolio?.client_designation}</span>
                        </div>
                        <div className="client-review">
                            <p>{porfolio?.client_review}</p>
                            {porfolio?.case_study_link !== null && <div className="button">
                                <button 
                                    onClick={() => { navigate(`/case-study/${porfolio?.case_study_link}`) }}
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}>
                                    <Link className='portfolio-page'><span>VIEW CASE STUDY</span></Link><span className='img'>
                                        {isHovered ? (
                                            <img src={GradientArrowIcon} alt="gradient-icon" />
                                        ) : (
                                            <img src={ArrowIconwhite} alt="arrow-icon" />
                                        )}
                                    </span>
                                </button>
                            </div>}
                        </div>
                    </div>
                </div>
            )}

            <div className="start-your-project">
                <h2>Embark on Your <span>Dream Project</span> Journey With Us</h2>
                <p>We provide exceptional, user-friendly healthcare solutions that improve patient care and ensure data privacy. Allow us to turn your vision into reality with our premier healthcare app development services.</p>
                <div className="button">
                    <button 
                        onClick={() => { navigate(`/contact-us`) }}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}>
                        <Link className='portfolio-page' to="/contact-us"><span>START YOUR PROJECT TODAY</span></Link><span className='img'>
                            {isHovered ? (
                                <img src={GradientArrowIcon} alt="gradient-icon" />
                            ) : (
                                <img src={ArrowIcon} alt="arrow-icon" />
                            )}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SinglePortfolioPage;
