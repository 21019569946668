import React, { useEffect, useState } from 'react';
import { useLocation ,useParams } from 'react-router-dom';
import  eyeIconImage  from '../assets/svgs/eyeicon.svg'
import '../components-css/single-blog-page.scss'
import Loader from '../components/loader'
import axios from 'axios'

const SingleBlogPage = () => {

  const [singleBlogData, setSingleBlogData] = useState({})
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const getBlogById = async () => {
      try {
        let payload = {
          id: id
        }
        let response = await axios.post('https://newomkatech.omkatech.in/api/GetBlogdetails', payload)
        setSingleBlogData(response.data.data)
        console.log('response', response)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }

    getBlogById()
  }, [id])

  function formatDate(isoString) {
    const date = new Date(isoString);

    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  const isoString = singleBlogData.created_at;
  const formattedDate = formatDate(isoString);

  const location = useLocation();
  console.log('location', location)
  return (
    <div className='single-blog-page-container'>
      {
        loading ? (<Loader />) : (
          <>
            <h1>{singleBlogData.title}</h1>
            <span className='time'>{singleBlogData.minute_read}-MINUTE READ</span>
            <span className='date'>{formattedDate}</span>
            <div className="image">
              <img src={singleBlogData.file_path + singleBlogData.image} alt="blog-icon" />
            </div>
            <p dangerouslySetInnerHTML={{ __html: singleBlogData.description }} />
            <div className='writer-info'>
              <div className='views'>
                  <img src={eyeIconImage} alt="icon-image" /> 
                  <p>365 Views</p>
              </div>
              <div className='writer'>
                <p>WRITTEN BY :</p>
                <span>{singleBlogData.written_by}</span>
                <p>Certified Product Owner.</p>
              </div>
            </div>
          </>
        )
      }
    </div>
  );
};

export default SingleBlogPage;