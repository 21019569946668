import React from 'react'
import '../components-css/privacy-policy.scss'

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-container">
            <h1>Privacy Policy</h1>
            <div className="content">
                At Omka Tech, we are committed to protecting your privacy and ensuring the security of any personal data you share with us. This Privacy Policy explains how we collect, use, and safeguard your information when you visit our website or use our services. By accessing our website, you agree to the terms outlined in this policy.
                <p>1. Information We Collect</p>
                We collect both personal and non-personal information to enhance your experience and provide seamless services. This includes:
                Personal Information: Your name, email address, phone number, and other details, provided voluntarily through contact forms, account registrations, or transactions.
                Non-Personal Information: Data such as your IP address, browser type, device details, and browsing behaviour, collected via cookies, log files, and analytics tools.
                <p>2. How We Use Your Information</p>
                We use your information for the following purposes:
                To provide, maintain, and improve our services.
                To respond to inquiries, and fulfil your requests.
                To send administrative communications, service updates, and promotional offers (with your consent).
                To analyze website traffic, detect security threats, and ensure compliance with our terms.
                <p>3. IP Addresses and Analytics</p>
                Each time you access the internet, your device is assigned a unique IP address. Our web server collects these addresses as part of traffic monitoring to strengthen security, optimize user experience, and analyze demographic trends.
                <p>4. Sharing and Selling of Information</p>
                We do not sell, rent, or trade your personal information with third parties. However, we may share your data with trusted service providers for website analytics, and service enhancements—strictly under confidentiality agreements.
                <p>5. Cookies and Tracking Technologies</p>
                Our website uses cookies and tracking technologies to improve user experience. These tools help us:
                Save your preferences and login credentials for a personalized experience.
                Monitor user interactions to optimize website functionality and performance.
                You can manage your cookie preferences through your browser settings. Please note that disabling cookies may impact certain website functionalities.
                <p>6. Your Rights and Choices</p>
                Depending on your location, you may have rights under applicable privacy laws, including:
                Accessing, correcting, or deleting your personal information.
                Opting out of marketing communications.
                Restricting or objecting to data processing in specific circumstances.
                To exercise these rights, please contact us at info@omkatech.com
                <p>7. Data Security Measures</p>
                We utilize advanced security protocols to protect your personal data from unauthorized access, misuse, or loss. However, no online system is entirely infallible, so we recommend exercising caution when sharing sensitive information on the internet.
                <p>8. External Links Disclaimer</p>
                Our website may include links to third-party sites for reference. However, we do not control or take responsibility for their content, privacy practices, or security measures. Once you navigate away from our site, our Privacy Policy no longer governs your interactions. We recommend reviewing the privacy policies of external websites before providing any personal information.
                <p>9. Updates to This Privacy Policy</p>
                We may revise this Privacy Policy at any time, and any updates will be reflected on this page along with the revised effective date. To stay informed about how we safeguard your information, we encourage you to review this policy regularly.
                <p>10. Contact Us</p>
                If you have any questions about this Privacy Policy or how your information is handled, please reach out to us:
                Email: info@omkatech.com
                Corporate office: S-04, H-17, H block,  Sector 63, Noida- 201301. UP, India
                USA Office: Dallas, TX, USA
            </div>
        </div>
    )
}

export default PrivacyPolicy