import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../components-css/popup.scss';
import crossIcon from '../assets/svgs/cross.svg';
import ArrowIcon from '../assets/svgs/Group 3.svg';
import downloadImg from '../assets/svgs/download.svg';
import GradientArrowIcon from '../assets/svgs/Group 3 (5).svg';
import { BASE_URL } from '../config';
import axios from 'axios';

const Popup = ({ jobTitle, onClose, id }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [formData, setFormData] = useState({
        job_id: id || "",
        name: "",
        email: "",
        phone: "",
        address: "",
        experience_in_years: "",
        current_employer: "",
        notice_period: "",
        current_salary: "",
        expected_salary: "",
        link: "",
        resume: null
    });
    const [resumeName, setResumeName] = useState(""); // Stores file name for display

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData(prevState => ({ ...prevState, resume: file }));
            setResumeName(file.name); // Show file name after selection
        }
    };

    const jobApply = async (e) => {
        e.preventDefault();

        try {
            let payload = new FormData();
            Object.keys(formData).forEach(key => {
                payload.append(key, formData[key]);
            });

            let response = await axios.post(`${BASE_URL}/apply-job`, payload, {
                headers: { "Content-Type": "multipart/form-data" }
            });

            toast.success("Application submitted successfully!", { autoClose: 3000 });
            
            // Optional: Close popup after successful submission
            setTimeout(() => {
                onClose();
            }, 3000);
        } catch (error) {
            // console.log('error coming')
            // console.error("Error:", error);
            toast.error(error.response.data.message);
        }
    };

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <ToastContainer />
                <div className="cross-icon">
                    <img onClick={onClose} src={crossIcon} alt="Close" />
                </div>
                <p className='apply'>APPLY FOR JOB</p>
                <h2>{jobTitle}</h2>
                <p>at OmkaTech</p>
                <form onSubmit={jobApply}>
                    <input type="text" name="name" placeholder='Your name' required onChange={handleChange} />
                    <input type="email" name="email" placeholder='Email address' required onChange={handleChange} />
                    <input type="number" name="phone" placeholder='Phone' required onChange={handleChange} />
                    <input type="text" name="address" placeholder='Address' required onChange={handleChange} />
                    <input type="number" name="experience_in_years" required placeholder='Experience in Years' onChange={handleChange} />
                    <input type="text" name="current_employer" required placeholder='Current Employer' onChange={handleChange} />
                    <input type="text" name="notice_period" required placeholder='Notice Period' onChange={handleChange} />
                    <input type="number" name="current_salary" placeholder='Current Salary' required onChange={handleChange} />
                    <input type="number" name="expected_salary" placeholder='Expected Salary' required onChange={handleChange} />
                    <input type="text" name="link" placeholder='LinkedIn Profile or Portfolio Link' required onChange={handleChange} />
                    
                    <input type="file" id="fileInput" className="file-input" onChange={handleFileChange} />
                    <label htmlFor="fileInput" className="file-label">
                        <div className="file-type-design">
                            <img src={downloadImg} alt="Upload Resume" />
                            <h2>Please Upload Your CV / Resume</h2>
                        </div>
                    </label>
                    {resumeName && <p className="file-name">📄 {resumeName}</p>} {/* Show file name after upload */}
                    
                    <p className='update-resume'>If you are unable to submit your details, please share your updated resume at hr@omkatech.com </p>
                    
                    <div className="button">
                        <button
                            type="submit"
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            <span>APPLY NOW</span>
                            <span className='img'>
                                {isHovered ? (
                                    <img src={GradientArrowIcon} alt="gradient-icon" />
                                ) : (
                                    <img src={ArrowIcon} alt="arrow-icon" />
                                )}
                            </span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Popup;
