import React, { useEffect, useState } from 'react';
import '../components-css/about-us.scss';
import axios from 'axios';
import { BASE_URL } from '../config';
import AOS from 'aos';
import 'aos/dist/aos.css';

function AboutUs() {
    const [aboutData, setAboutData] = useState(null);

    const fetchAboutData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/aboutus`);
            setAboutData(response?.data?.data);
        } catch (error) {
            console.error('Error fetching About Us data:', error);
        }
    };

    useEffect(() => {
        fetchAboutData();
        AOS.init({ duration: 1000, once: true, offset: 50 }); // Initialize AOS with animation duration
    }, []);

    return (
        <div className='about-main-container'>
            <div className="about">
                <div className="heading" data-aos="fade-left"> 
                    <h1>{aboutData?.home_heading}</h1>
                </div>
                <div className="company-brief" data-aos="fade-right">
                    <div dangerouslySetInnerHTML={{ __html: aboutData?.home_description }} />
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
