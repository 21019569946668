import React, { useState } from 'react';
import kite from '../pngs/Group 274 1.png';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import '../components-css/contact-us.scss';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactUs = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [project, setProject] = useState('');
    const [selectedServices, setSelectedServices] = useState([]);
    const [isHovered, setIsHovered] = useState(false);
    
    const validatePhone = (value) => {
        const phoneRegex = /^[0-9]{10,15}$/; // Allows numbers between 10 to 15 digits
        if (!phoneRegex.test(value)) {
            toast.error("Invalid phone number. Must be 10-15 digits.", { toastId: "invalid-phone" });
            return false;
        }
        return true;
    };

    // const validateName = (value) => {
    //     const nameRegex = /^[A-Za-z\s]+$/; // Allows only alphabets and spaces
    //     if (!nameRegex.test(value)) {
    //         toast.error("Invalid name. Only alphabets and spaces are allowed.", { toastId: "invalid-name" });
    //         return false;
    //     }
    //     return true;
    // };

    const postContact = async () => {
        if (!name || !email || !phone || !project) {
            toast.dismiss();
            toast.error("All fields are required.", { toastId: "required-fields" });
            return;
        }

        if (!validatePhone(phone)) {
            return;
        }
        // if (!validateName(name)) {
        //     return;
        // }

        let payload = {
            name,
            email,
            number: '+' + phone,
            about_project: project,
            service: selectedServices
        };

        try {
            let response = await axios.post('https://newomkatech.omkatech.in/api/contact-us', payload);

            setName('');
            setEmail('');
            setPhone('');
            setProject('');
            setSelectedServices([]);

            toast.dismiss();
            toast.success(response?.data?.message || "Message sent successfully!", { toastId: "success-msg" });

        } catch (error) {
            toast.dismiss();
            toast.error(error?.response?.data?.message || "Failed to send your message. Please try again.", { toastId: "error-msg" });
        }
    };

    const toggleHighlight = (service) => {
        setSelectedServices((prev) =>
            prev.includes(service) ? prev.filter((item) => item !== service) : [...prev, service]
        );
    };

    const services = [
        'Mobile App Development',
        'Web Design & Development',
        'Software Development',
        'SEO',
        'UI/UX Design',
        'Digital Marketing',
        'IT Managed Services',
    ];

    return (
        <div className="contact-us-main-container">
            <ToastContainer />
            <div className="contact-heading">
                <h1>
                    Hey! <span>Tell us</span> what you are looking for?
                </h1>
                <img src={kite} alt="kite-png" />
            </div>
            <div className="contact-us">
                <div className="your-name">
                    <input
                        type="text"
                        value={name}
                        placeholder="Your Name"
                        onChange={(e) => {
                            const value = e.target.value;
                            const nameRegex = /^[A-Za-z\s]*$/; // Allows only alphabets and spaces
                            if (nameRegex.test(value)) {
                                setName(value);
                            } else {
                                toast.error("Only alphabets and spaces are allowed.", { toastId: "invalid-name" });
                            }
                        }}
                    />
                </div>
                <div className="your-name">
                    <input
                        type="email"
                        value={email}
                        placeholder="Your Email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="your-name flag">
                    <PhoneInput
                        country="in"
                        value={phone}
                        onChange={setPhone}
                        inputStyle={{
                            width: '100%',
                            paddingLeft: '48px',
                            fontSize: '20px',
                            color: '#7F7F7F'
                        }}
                        placeholder="Enter phone number"
                        enableSearch={true}
                        searchPlaceholder="Search country"
                    />
                </div>
                <div className="your-name">
                    <input
                        type="text"
                        value={project}
                        onChange={(e) => setProject(e.target.value)}
                        placeholder="Tell us about your project"
                    />
                </div>
                <div className="choose-service">
                    <p>Choose your Service</p>
                    <div className="services-container">
                        {services.map((service, index) => (
                            <div
                                key={index}
                                className={`service ${selectedServices.includes(service) ? 'active' : ''}`}
                                onClick={() => toggleHighlight(service)}
                            >
                                {service}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="button">
                    <button
                        onClick={postContact}
                        className="btn-bg"
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        <span>Submit</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;