import React, {useState,useEffect, useRef, useCallback} from 'react'
import BlackComma from '../assets/svgs/black-comma.svg'
import Slider from 'react-slick';
import clientImg from '../pngs/Ellipse 30.png'
import smileVideo from '../pngs/smile.mp4'
import leftArrow from '../assets/svgs/Vector.svg';
import rightArrow from '../assets/svgs/Vector (1).svg';
import VinitImg from '../pngs/Vinit.png' 
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import KarinaImg from '../pngs/Karina.png'
import SauravImg from '../pngs/Saurav.png'
import SandhyaImg from '../pngs/Sandhya.png'
import RishabhImg from '../pngs/Rishabh.png'
import PoojaImg from '../pngs/Pooja.png'
import MohitImg from '../pngs/Mohit.png'
import ShikhaImg from '../pngs/Shikha.png'
import DiwakarImg from '../pngs/Diwakar.png'
import RohitImg from '../pngs/Rohit.png'
import ShubhamImg from '../pngs/Shubham.png'
import AyushiImg from '../pngs/Ayushi.png'
import VinodImg from '../pngs/Vinod.png'
import ManishImg from '../pngs/Manish.png'
import AnchalImg from '../pngs/Anchal.png'
import NitinImg from '../pngs/Nitin.png'
import AshishImg from '../pngs/Ashish.png'
import SimranImg from '../pngs/Simran.png'
import MuskanImg from '../pngs/Muskan.png'
import '../components-css/our-testimonial-page.scss'
import axios from 'axios'
import { BASE_URL } from '../config';

const OurTestimonialPage = () => {

  const [happyClients, sethappyClients] = useState(null)
  const [loading, setLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState({});
    const [showReadMore, setShowReadMore] = useState({});
    const reviewRef = useRef({});

    // useEffect(() => {
    //   console.log('reviewRef.current', reviewRef)
    //   if (reviewRef.current && reviewRef.current.clientHeight > 200) {
    //     setShowReadMore(true);
    //   }
    // }, []);


    // Use callback ref to ensure ref is properly assigned
    const setReviewRef = useCallback((node, index) => {
      if (node) {
        reviewRef.current[index] = node;
        const height = node.clientHeight;
    
        // Update state only if it hasn't been set yet to prevent re-renders
        setShowReadMore((prev) => {
          if (!prev[index] && height > 199) {
            return { ...prev, [index]: true };
          }
          return prev;
        });
      }
    }, []);

  useEffect(() => {
    const testimonialData = async () => {
        try{
            let response = await axios.get(`${BASE_URL}/testimonial`)
            sethappyClients(response.data.data)
          }
          catch(err){
              console.log(err)
          }
          finally{
              setLoading(false)
          }
      }
      
      testimonialData()
  }, [])
  
  const [teamPhotos, setTeamPhotos] = useState([
    {
      name: "Saurav",
      designation: 'Finance Manager',
      img: "/Career page images/Mask-group-1.png",
    },
    {
      name: "Karina Bakshi",
      designation: 'HR Executive',
      img: "/Career page images/Mask-group-2.png",
    },
    {
      name: "Sandhya Kumari",
      designation: 'Senior UX/UI Designer',
      img: "/Career page images/Mask-group-3.png",
    },
    {
      name: "Rishabh Madan",
      designation: 'Full Stack Developer (US)',
      img: "/Career page images/Mask-group-4.png",
    },
    {
      name: "Vinit Vijayran",
      designation: 'Full Stack Developer',
      img: "/Career page images/Mask-group-5.png",
    },
    {
      name: "Pooja Arya",
      designation: 'Lead Php Software Developer',
      img: "/Career page images/Mask-group-6.png",
    },
    {
      name: "Mohit Kumar",
      designation: 'Php Developer',
      img: "/Career page images/Mask-group-7.png",
    },
    {
      name: "Shikha Pal",
      designation: 'Php Developer',
      img: "/Career page images/Mask-group.png",
    },
    // {
    //   name: "Diwakar Kumar",
    //   designation: 'Senior Frontend Developer',
    //   img: DiwakarImg,
    // },
    // {
    //   name: "Rohit Satyarthi",
    //   designation: 'Frontend Developer',
    //   img: RohitImg,
    // },
    // {
    //   name: "Shubham Tanwar",
    //   designation: 'Flutter Mobile App Developer',
    //   img: ShubhamImg,
    // },
    // {
    //   name: "Ayushi Bhatnagar",
    //   designation: 'Flutter Developer',
    //   img: AyushiImg,
    // },
    // {
    //   name: "Vinod Kumar",
    //   designation: 'Manual Tester',
    //   img: VinodImg,
    // },
    // {
    //   name: "Manish Singh",
    //   designation: 'Digital Marketing Executive',
    //   img: ManishImg,
    // },
    // {
    //   name: "Anchal Srivastava",
    //   designation: 'Digital Marketing Specialist',
    //   img: AnchalImg,
    // },
    // {
    //   name: "Nitin Sahu",
    //   designation: 'Email Marketing Executive',
    //   img: NitinImg,
    // },
    // {
    //   name: "Ashish Pratap",
    //   designation: 'Email Marketing Specialist',
    //   img: AshishImg,
    // },
    // {
    //   name: "Simran Mehta",
    //   designation: 'Email Marketing Specialist',
    //   img: SimranImg,
    // },
    // {
    //   name: "Muskan Tomar",
    //   designation: 'Business Development Executive',
    //   img: MuskanImg,
    // }
  ])

  

  // Slick Slider settings
  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 3 items on large screens
    slidesToScroll: 1,
    arrows: false, // Hide arrows for smaller screens
    responsive: [
      {
        breakpoint: 1024, // Tablet screens
        settings: {
          slidesToShow: 2, // Show 2 items
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Mobile screens
        settings: {
          slidesToShow: 1, // Show 1 item
          slidesToScroll: 1,
        },
      },
    ],
  };
  
  const settings2 = {
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // Tablet breakpoint
        settings: {
          slidesToShow: 3, // Show 3 slides on tablet
        },
      },
      {
        breakpoint: 767, // Mobile breakpoint
        settings: {
          slidesToShow: 1, // Show 2 slides on mobile
        },
      },
    ],
    nextArrow: null,
    prevArrow: null,
  };
  
  return (
    <>
    <div className='Our-testimonial-page-main-container'>
      <div className="core-team-container">
        <div className="left-content">
          <h1>TESTIMONIALS</h1>
          <p>Words of praise from others about our presence.</p>
        </div>
        <div className="right-content">
          {teamPhotos?.map((team, index) => (
            <img key={index} src={team.img} alt={team.name} />
          ))}
        </div>
      </div>
      {/* <div className="our-testimonial-banner">
        <div className="testimonial-left-content">
          <h1>OUR TESTIMONIALS</h1>
          <p>Words of praise from others about our presence.</p>
        </div>
        <div className="testimonial-right-content">
          <img className="testimonial-image" src={img3} alt="img-1" />
          <img className="testimonial-image" src={img1} alt="img-1" />
          <img className="testimonial-image" src={img1} alt="img-1" />
          <img className="testimonial-image" src={img4} alt="img-1" />
          <img className="testimonial-image" src={img5} alt="img-1" />
          <img className="testimonial-image" src={img1} alt="img-1" />
          <img className="testimonial-image" src={img2} alt="img-1" />
        </div>
      </div> */}
      <div className="happy-clients">
        <h2>Here are some <b> Happy Clients</b> <video className="smiley-rotate" autoPlay loop muted playsInline preload="auto">
          <source type="video/mp4" src={smileVideo} />
          <source type="video/webm" src={smileVideo} />
        </video></h2>

        <div className='happy-clients-cards-responsive'>
          <Slider {...settings2}>
          {happyClients?.map((client) => {
            return <div className="happy-client-card-responsive">
                <img src={BlackComma} alt="black-comma" />
                <p className='review'>{client.review}</p>
                <div className="about-client">
                <div className='image-div'>
                  <img src={`${client.file_path}${client.image}`} alt="client-img" />
                </div>
                  <div className="client-content">
                    <h5>{client.client_name}</h5>
                    <p>{client.client_designation} of {client.client_company}</p>
                  </div>
                </div>
              </div>
            })}
          </Slider>
        </div>


        <div className="happy-clients-cards">
    {happyClients?.map((client, index) => (
      <div className="happy-client-card" key={index}>
        <img src={BlackComma} alt="black-comma" />
        <p
          ref={(node) => setReviewRef(node, index)}
          className={`review-text ${isExpanded[index] ? "expanded" : ""}`}
          style={{ maxHeight: isExpanded[index] ? "none" : "200px", overflow: "hidden" }}
        >
          {client.review}
        </p>
        {showReadMore[index] && (
          <button
            onClick={() => setIsExpanded((prev) => ({ ...prev, [index]: !prev[index] }))}
            className="read-more-btn"
          >
            {isExpanded[index] ? "Read Less" : "Read More"}
          </button>
        )}
        <div className="about-client">
          <div className="image-div">
            <img src={`${client.file_path}${client.image}`} alt="client-img" />
          </div>
          <div className="client-content">
            <h5>{client.client_name}</h5>
            <p>{client.client_designation} of {client.client_company}</p>
          </div>
        </div>
      </div>
    ))}
  </div>
      </div>
      
    </div>
    <div className="client-say-about-us">
      <h2>What Our Clients Say About Us</h2>
      <Swiper
        modules={[Autoplay, Pagination]}
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        loop={true}
        speed={500}
        spaceBetween={40}
        pagination={{ clickable: true }}
        breakpoints={{
          320: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          1024: { slidesPerView: 3 },
        }}
      >
        {happyClients?.map(
          (videoUrl, index) =>
            videoUrl?.youtube_video_link && (
              <SwiperSlide key={index}>
                <iframe
                  width="408"
                  height="275"
                  src={videoUrl.youtube_video_link}
                  title={`YouTube video ${index + 1}`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </SwiperSlide>
            )
        )}
      </Swiper>
    </div>
  </>
  )
}
const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="slider-buttons testimonial mt">
      <div className="slider-button-left" onClick={onClick}><img src={leftArrow} alt="left-arrow-icon" /></div>
    </div>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="slider-buttons testimonial mt">
      <div className="slider-button-right" onClick={onClick}><img src={rightArrow} alt="right-arrow-icon" /></div>
    </div>
  );
};

export default OurTestimonialPage