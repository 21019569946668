import React, { useState, useEffect } from 'react'
import '../components-css/consult-with-omkatech.scss'
import axios from 'axios'
import { BASE_URL } from '../config'
import { ToastContainer, toast } from 'react-toastify';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ConsultWithOmkatech = () => {
  const [email, setEmail] = useState('')

  useEffect(() => {
    AOS.init({ duration: 1000, once: true, offset: 50 }); // Initialize AOS with animation duration
}, []);

  const sendEmail = async () => {
    try{
      let payload = {
        email: email
      }
      let response = await axios.post(`${BASE_URL}/consult-with-us`, payload)
      if(response.data && response.data.status == true){
        setEmail('');
        toast.success(response.data.message);
      }
    } catch (error){
      if(error.response){
        toast.error(error.response.data.message);
      }else{
        toast.error(error.message);
      }
    }
    
    
  }
  
  return (
    <div className='consult-main-container' data-aos="fade-top">
        <ToastContainer />
        <div className="top-footer-part">
        <h1>Consult with <b>OMKA TECH!</b></h1>
        <p>Book Your First Presentation with Our <b>Experts Today.</b></p>
        <div className='input-container'>
          <input type="text" value={email} onChange={(e)=> {setEmail(e.target.value)}} placeholder='Email Address' />
          <button disabled={!email} onClick={sendEmail}>Send</button>
        </div>
      </div>
    </div>
  )
}

export default ConsultWithOmkatech