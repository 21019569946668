import React, { useState } from 'react';
import '../components-css/PopupYoutube.scss';
import crossIcon from '../assets/svgs/cross.svg'

const Popupyoutube = ({ link, onClose }) => {
    const [isHovered, setIsHovered] = useState(false);
    { console.log('checking',link) }
    return (
        <div className="popup-overlay-youtube">
            <div className="popup-content">
                <div className="cross-icon">
                    <img onClick={onClose} src={crossIcon} alt="" />
                </div>
                
                <div>
                    <iframe
                        width="100%"
                        height="500px"
                        src={`${link}&autoplay=1&mute=1`}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
             
              
               
            </div>
        </div>
    );
};

export default Popupyoutube;
