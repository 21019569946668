import React, { useEffect, useState } from 'react'
import carrerImg from '../pngs/Group 540.png'
import EmployeeImg from '../pngs/image 25.png'
import groupImg from '../assets/svgs/group.svg'
import axios from 'axios'
import DaysWorkWeek from '../assets/svgs/Perks-of-Joining-Us/5-Days-Work-Week.svg'
import FlexibleTimings from '../assets/svgs/Perks-of-Joining-Us/Flexible-Timings.svg'
import ExcitingChallenges from '../assets/svgs/Perks-of-Joining-Us/Exciting-Challenges.svg'
import CelebrationsEvents from '../assets/svgs/Perks-of-Joining-Us/Celebrations-Events.svg'
import OpenCommunication from '../assets/svgs/Perks-of-Joining-Us/Open-Communication.svg'
import SupportiveEnvironment from '../assets/svgs/Perks-of-Joining-Us/Supportive-Environment.svg'
import EnterpriseLevelProjects from '../assets/svgs/Perks-of-Joining-Us/Enterprise-Level-Projects.svg'
import GlobalStandardProjects from '../assets/svgs/Perks-of-Joining-Us/Global-Standard-Projects.svg'
import WorkLifeBalance from '../assets/svgs/Perks-of-Joining-Us/Work-Life-Balance.svg'

import HappyOnboarding from '../assets/svgs/Our-Hiring-Process/HappyOnboarding.svg'
import PersonalInterview from '../assets/svgs/Our-Hiring-Process/Personal-Interview.svg'
import VirtualEvaluationRound from '../assets/svgs/Our-Hiring-Process/Virtual-EvaluationRound.svg'
import { BASE_URL } from '../config';



// import groupImgMK from '../assets/svgs/Mohit.svg'
import processImg from '../assets/svgs/process-card-img.svg'
import ArrowIcon from '../assets/svgs/Group 3.svg';
import GradientArrowIcon from '../assets/svgs/Group 3 (5).svg'
import { Link } from "react-router-dom";
import Popup from '../components/popup'; // Import Popup component
import '../components-css/carrer-page.scss'

const CarrerPage = () => {
    const [isHovered, setIsHovered] = useState(false);
    const [active, setActive] = useState(false);
    const [showPopup, setShowPopup] = useState(false); // State to manage popup visibility
    const [getApplyJobs, setGetApplyJobs] = useState([])
    const [selectedJobTitle, setSelectedJobTitle] = useState(''); // State to manage selected job title
    const [id, setId] = useState(''); // State to manage selected job title

    const handleApplyClick = (jobTitle, id) => {
        setId(id)
        setSelectedJobTitle(jobTitle);
        setShowPopup(true);
        console.log('check')
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        setSelectedJobTitle('');
    };

    useEffect(() => {
        getJobs();
    }, [])

    const getJobs = async () => {
        try {
            let response = await axios.get('https://newomkatech.omkatech.in/api/get-jobs')
            setGetApplyJobs(response?.data?.data)
            console.log('response job apply', response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className='carrer-page-main-container'>
            {showPopup && <Popup jobTitle={selectedJobTitle} id={id}  onClose={handleClosePopup} />} {/* Render Popup if showPopup is true */}
            <div className="carrer-with-us-section">
                <div className="left">
                    <h3>CAREER WITH OMKA TECH</h3>
                    <h1>Unlock Your Potential with Competitive and Rewarding Opportunities at <br></br><span>OMKA TECH</span></h1>
                    <p>At Omka Tech, we believe in nurturing talent and providing a stable platform for growth. Be part of a fast-evolving company where innovative and challenging projects pave the way for personal and professional excellence.</p>
                    <p>OMKA TECH <span className='career-italic'>gives you a full-blown career.</span></p>
                </div>
                <div className="right">
                    <img src={carrerImg} alt="banner-img" />
                </div>
            </div>
            <div className="employee-card">
                <div className="images">
                    <img src="/Career page images/Ellipse 50.png" alt="employee-img" />
                    <img src="/Career page images/Ellipse 51.png" alt="employee-img" />
                    <img src="/Career page images/Ellipse 53.png" alt="employee-img" />
                    <img src="/Career page images/Mask group (24).png" alt="employee-img" />
                    <img src="/Career page images/Mask group (25).png" alt="employee-img" />
                    <img src="/Career page images/Mask group (26).png" alt="employee-img" />
                    <img src="/Career page images/Mask group (27).png" alt="employee-img" />
                </div>
                <span>&</span>
                <p><span>This</span> Could be you!</p>
            </div>
            <div className="join-us">
                <h4>Come <span>JOIN US</span> to create wow factor...</h4>
            </div>
            <div className="perks-section">
                <h3>Perks of Joining Us!</h3>
                <p>At Omka Tech, you're not just an employee you’re family. We care deeply about your personal growth and professional success. Our aim is to
                    provide a balanced work-life experience, fostering both productivity and peace of mind.</p>
                <div className="perks-cards">
                    <div className="card">
                        <img src={groupImg} alt="" />
                        <h4>Employee First</h4>
                    </div>
                    <div className="card">
                        <img src={DaysWorkWeek} alt="" />
                        <h4>5 Days Work Week</h4>
                    </div>
                    <div className="card">
                        <img src={FlexibleTimings} alt="" />
                        <h4>Flexible Timings</h4>
                    </div>
                    <div className="card">
                        <img src={WorkLifeBalance} alt="" />
                        <h4>Work-Life Balance</h4>
                    </div>
                    <div className="card">
                        <img src={ExcitingChallenges} alt="" />
                        <h4>Exciting Challenges</h4>
                    </div>
                    <div className="card">
                        <img src={CelebrationsEvents} alt="" />
                        <h4>Celebrations & Events</h4>
                    </div>
                    <div className="card">
                        <img src={OpenCommunication} alt="" />
                        <h4>Open Communication</h4>
                    </div>
                    <div className="card">
                        <img src={SupportiveEnvironment} alt="" />
                        <h4>Supportive Environment</h4>
                    </div>
                    <div className="card">
                        <img src={EnterpriseLevelProjects} alt="" />
                        <h4>Enterprise Level Projects</h4>
                    </div>
                    <div className="card">
                        <img src={GlobalStandardProjects} alt="" />
                        <h4>Global-Standard Projects</h4>
                    </div>

                </div>
            </div>
            <div className="our-hiring-process-section">
                <h2>Our Hiring Process</h2>
                <div className='upward-curve-main'>
                    <div className='upward-curve'>
                        <svg width="100%" height="auto" viewBox="0 0 500 200" preserveAspectRatio="xMidYMid meet">
                            <path d="M20,150 Q250,0 480,150" stroke="gray" fill="transparent" stroke-width="2" stroke-dasharray="3,3"></path>
                            <defs>
                                <marker id="arrowhead" markerWidth="7" markerHeight="5" refX="5" refY="2.5" orient="auto">
                                    <polygon points="0 0, 7 2.5, 0 5" fill="gray"></polygon>
                                </marker>
                            </defs>
                            <path d="M20,150 Q250,0 480,150" stroke="gray" fill="transparent" stroke-width="2" stroke-dasharray="3,3" marker-end="url(#arrowhead)"></path>
                        </svg>
                    </div>
                    <div className='upward-curve'>
                        <svg width="100%" height="auto" viewBox="0 0 500 200" preserveAspectRatio="xMidYMid meet">
                            <path d="M20,150 Q250,0 480,150" stroke="gray" fill="transparent" stroke-width="2" stroke-dasharray="3,3"></path>
                            <defs>
                                <marker id="arrowhead" markerWidth="7" markerHeight="5" refX="5" refY="2.5" orient="auto">
                                    <polygon points="0 0, 7 2.5, 0 5" fill="gray"></polygon>
                                </marker>
                            </defs>
                            <path d="M20,150 Q250,0 480,150" stroke="gray" fill="transparent" stroke-width="2" stroke-dasharray="3,3" marker-end="url(#arrowhead)"></path>
                        </svg>
                    </div>
                </div>
                <div className="process-cards">
                    <div className="card">
                        <img src={processImg} alt="" />
                        <p>Initial HR Round</p>
                    </div>
                    <div className="card">
                        <img src={VirtualEvaluationRound} alt="" />
                        <p>Virtual Round</p>
                    </div>
                    <div className="card">
                        <img src={PersonalInterview} alt="" />
                        <p>Personal Interview</p>
                    </div>
                    <div className="card">
                        <img src={HappyOnboarding} alt="" />
                        <p>Happy Onboarding</p>
                    </div>
                </div>
                <div className='downward-curve-main'>
                    <div className='downward-curve'>
                        <svg width="100%" height="auto" viewBox="0 0 500 150" preserveAspectRatio="xMidYMid meet">
                            <path d="M10,20 Q250,170 490,20" stroke="gray" fill="transparent" stroke-width="2" stroke-dasharray="3,3"></path>
                            <defs>
                                <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="8" refY="3.5" orient="auto">
                                    <polygon points="0 0, 10 3.5, 0 7" fill="gray"></polygon>
                                </marker>
                            </defs>
                            <path d="M10,20 Q250,170 490,20" stroke="gray" fill="transparent" stroke-width="2" stroke-dasharray="3,3" marker-end="url(#arrowhead)">
                            </path>
                        </svg>
                    </div>
                </div>
            </div>
            <div className="job-openings-section">
                <h2>Job Openings</h2>
                <p>At Omka Tech, we are always on the lookout for passionate and talented professionals who aspire to make a difference in the tech industry. Join us to work on cutting-edge projects, grow your skills, and shape the future with innovative solutions. Explore the opportunities below and take the next step in your career with us!" <br /> <br /> Email us your updated Résumé/CV at <span> hr@omkatech.com</span> We are currently hiring for the below positions:</p>
                <div className="jobs-cards-section">
                    {getApplyJobs.length === 0 ? (
                        <p>Loading jobs...</p>
                    ) : (
                        getApplyJobs.map((job, index) => (
                            <div>
                            <div key={index} className="jobs-card">
                                <div className="left-section" onClick={() => setActive(!active)}>
                                    <div className="job-heading">
                                        <h3>{job?.job_title}</h3>
                                        <span>Experience: <span>{job?.required_experience || "N/A"}</span></span>
                                    </div>
                                    <div className="hr"></div>
                                    <p>Job Description:</p>
                                    <span dangerouslySetInnerHTML={{ __html: job?.job_description || "Not Provided" }} />
                                </div>
                                <div className="button">
                                <button
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                    onClick={() => handleApplyClick(job?.job_title, job?.id)}
                                >
                                    <span>APPLY NOW</span><span className='img'>
                                        {isHovered ? (
                                            <img src={GradientArrowIcon} alt="gradient-icon" />
                                        ) : (
                                            <img src={ArrowIcon} alt="arrow-icon" />
                                        )}
                                    </span>
                                </button>
                            </div>
                            </div>
                            {active && <div className="hidden-details">
                                <hr />
                                <ul>
                                    <p>Skills Required:</p>
                                    <li dangerouslySetInnerHTML={{ __html: job?.skills_required || "Not Provided" }} />
                                </ul>
                            </div>}
                            </div>
                        ))
                    )}
                </div>
            </div>
            <div className="tagline">
                <h2>We Innovate Together, Flourish Together, and Grow Together.!!</h2>
            </div>
        </div>
    )
}

export default CarrerPage
