import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/autoplay';
import '../components-css/our-clients.scss';
import { Autoplay, Mousewheel } from 'swiper/modules';
import axios from 'axios';
import { BASE_URL } from '../config';
import AOS from 'aos';
import 'aos/dist/aos.css';

function OurClients({ checkcolor }) {
    const [hoveredLogo, setHoveredLogo] = useState(null);
    const [brandsData, setBrandsData] = useState(null);
    const [isColorCheck, setCheckColor] = useState(null);
    const swiperRef = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        if (checkcolor === "color") {
            setCheckColor(true);
        }
    }, [checkcolor]);

    useEffect(() => {
        const getClientsData = async () => {
            try {
                let response = await axios.get(`${BASE_URL}/brand`);
                setBrandsData(response.data.data);
            } catch (error) {
                console.log(error);
            }
        };
        getClientsData();
    }, []);

    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: true,
            offset: 50
        });

        const handleScroll = () => {
            AOS.refresh();
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && swiperRef.current) {
                    swiperRef.current.autoplay.start();
                } else {
                    swiperRef.current?.autoplay.stop();
                }
            },
            { threshold: 0.3 }
        );

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, []);

    return (
        <div className='our-clients-main-container' ref={containerRef}>
            <h1>We've revolutionized your interaction with these global giants.</h1>
            <div className="brands-logo">
                <Swiper
                    onSwiper={(swiper) => (swiperRef.current = swiper)}
                    slidesPerView={6}
                    spaceBetween={20}
                    loop={true}
                    mousewheel={{
                        forceToAxis: true,
                        sensitivity: 1,
                        thresholdDelta: 40,
                    }}
                    autoplay={{
                        delay: 1,
                        disableOnInteraction: false, // Keeps autoplay running after interaction
                    }}
                    speed={5000}
                    breakpoints={{
                        1024: { slidesPerView: 6 },
                        500: { slidesPerView: 3 },
                        280: { slidesPerView: 2 }
                    }}
                    modules={[Autoplay, Mousewheel]}
                >
                    {brandsData?.map((brand, index) => (
                        <SwiperSlide key={index}>
                            <div
                                className="img"
                                onMouseEnter={() => {
                                    setHoveredLogo(index);
                                    swiperRef.current?.autoplay.stop(); // 🔥 Instantly stops autoplay
                                }}
                                onMouseLeave={() => {
                                    setHoveredLogo(null);
                                    swiperRef.current?.autoplay.start(); // 🔥 Instantly starts autoplay again
                                }}
                                data-aos="fade-up"
                            >
                                {isColorCheck ? (
                                    <img src={brand.file_path + brand.image} alt={`Brand ${index + 1}`} />
                                ) : (
                                    <img
                                        src={hoveredLogo === index ? brand.file_path + brand.image : brand.file_path + brand.black_and_white_image}
                                        alt={`Brand ${index + 1}`}
                                    />
                                )}
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}

export default OurClients;
