import React, { useState, useEffect } from 'react';
import ArrowDown from '../assets/svgs/Group 32.svg';
import '../components-css/faq.scss';
import { BASE_URL } from '../config';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Faq = ({ service_id = null }) => {
    const [faqData, setFaqData] = useState([]);
    useEffect(() => {
        if (window.innerWidth < 768) {
            AOS.init({ disable: 'mobile' });
        } else {
            AOS.init({ duration: 1000, once: true, offset: 50 });
        }
    }, []);
    
    // Fetch FAQ data from the API
    const getFaqData = async () => {
        try {
            let payload = { id: service_id };
            let response = await axios.post(`${BASE_URL}/faq`, payload);
            const dataWithIsOpen = response.data.data.map((item) => ({
                ...item,
                isOpen: false,
            }));
            setFaqData(dataWithIsOpen);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getFaqData();
    }, [service_id]);

    const toggleAccordion = (index) => {
        setFaqData((prevFaqData) => 
            prevFaqData.map((item, i) => ({
                ...item,
                isOpen: i === index ? !item.isOpen : false,
            }))
        );
    };

    const half = Math.ceil(faqData.length / 2);
    const leftColumn = faqData.slice(0, half);
    const rightColumn = faqData.slice(half);

    return (
        <div className="faq-main-container">
            <h2>FAQ</h2>
            <div className="accordions">
                <div className="column" data-aos="fade-right">
                    {leftColumn.map((accordion, index) => (
                        <div className="accordion" key={index}>
                            <div className="heading" onClick={() => toggleAccordion(index)}>
                                <p>{accordion?.heading}</p>
                                <img
                                    className={accordion.isOpen ? 'activeFaq' : ''}
                                    src={ArrowDown}
                                    alt="arrow-down-icon"
                                />
                            </div>
                            <div 
                                className="content"
                                style={{
                                    maxHeight: accordion.isOpen ? '500px' : '0px',
                                    overflow: 'hidden',
                                    transition: 'max-height 0.3s ease-in-out',
                                }}
                            >
                                <div className='faq-answer' dangerouslySetInnerHTML={{ __html: accordion?.subheading }} />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="column" data-aos="fade-left">
                    {rightColumn.map((accordion, index) => (
                        <div className="accordion" key={index + half}>
                            <div className="heading" onClick={() => toggleAccordion(index + half)}>
                                <p>{accordion?.heading}</p>
                                <img
                                    className={accordion.isOpen ? 'activeFaq' : ''}
                                    src={ArrowDown}
                                    alt="arrow-down-icon"
                                />
                            </div>
                            <div 
                                className="content"
                                style={{
                                    maxHeight: accordion.isOpen ? '500px' : '0px',
                                    overflow: 'hidden',
                                    transition: 'max-height 0.3s ease-in-out',
                                }}
                            >
                                <div className='faq-answer' dangerouslySetInnerHTML={{ __html: accordion?.subheading }} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Faq;
