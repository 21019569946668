import React, { useEffect, useState } from 'react';
import '../components-css/portfolio-page.scss';
import ArrowIcon from '../assets/svgs/Group 3.svg';
import GradientArrowIcon from '../assets/svgs/Group 3 (5).svg';
import Project1 from '../pngs/Mask group (1).png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../config';

const PortfolioPage = () => {
    const [projects, setProjects] = useState([]);

    const [visibleProjects, setVisibleProjects] = useState(8);
    const navigate = useNavigate()
    const [isHovered, setIsHovered] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('all'); // Default category
    const [portfolioPageData, setPortfolioPageData] = useState(null)

    const loadMore = () => {
        setVisibleProjects((prevVisibleProjects) => prevVisibleProjects + 4); // Increase by 4, change as needed
    };

    const getPortfolioPageData = async () => {
        try {
            let response = await axios.get(`${BASE_URL}/portfolio`)
            console.log('check response portfolio page', response)
            setProjects(response.data.data)
            console.log("projects",projects)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(()=> {
        getPortfolioPageData()
    }, [])

    // Filter projects based on selected category
    const filteredProjects =
        selectedCategory === 'all'
            ? projects
            : projects.filter((project) => project.category === selectedCategory);

    return (
        <div className='portfolio-page-container'>
            <div className="portfolio-heading">
                <h1>PORTFOLIO</h1>
                <p>
                    A small sample of successfully completed projects by Never Settle. Take a closer
                    look at our Web Design, Web Development, Branding & Marketing projects completed
                    for Small Businesses, Enterprises, and Non-Profits.
                </p>
            </div>

            <div className="all-projects">
                {/* Tabs for filtering */}
                <div className="portfolio-tabs">
                    <button onClick={() => setSelectedCategory('all')} className={selectedCategory === 'all' ? 'active' : ''}>All</button>
                    <button onClick={() => setSelectedCategory('web-development')} className={selectedCategory === 'web-development' ? 'active' : ''}>Web Development</button>
                    <button onClick={() => setSelectedCategory('mobile-apps')} className={selectedCategory === 'mobile-apps' ? 'active' : ''}>Mobile Apps</button>
                    <button onClick={() => setSelectedCategory('branding')} className={selectedCategory === 'branding' ? 'active' : ''}>Branding</button>
                    <button onClick={() => setSelectedCategory('wordpress')} className={selectedCategory === 'wordpress' ? 'active' : ''}>WordPress</button>
                </div>
                <div className="projects">
                    {filteredProjects.slice(0, visibleProjects)?.map((project, index) => (
                        <div key={index} className="project">
                            <div className="images-container">
                                <img src={ `${project?.banner_path}${project?.banner_image}`} alt={`project${index + 1}-icon`} className="project-image" />
                                <div className="overlay">
                                    <button onClick={() => { navigate(`/portfolio/${project.id}`) }}>
                                    Portfolio Detail <img src={GradientArrowIcon} alt="" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {visibleProjects < filteredProjects.length && (
                    <div className="button btn-center">
                        <button
                            onClick={loadMore}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            <span>Load More</span>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PortfolioPage;
