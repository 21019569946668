import React, { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Mousewheel } from 'swiper/modules';
import '../components-css/portfolio.scss'; // Your styles
import axios from 'axios';
import { BASE_URL } from '../config';
import { useNavigate } from 'react-router-dom';
import ArrowIcon from '../assets/svgs/black-arrow.svg';
import GradientArrowIcon from '../assets/svgs/Group 3 (5).svg';
import leftArrow from '../assets/svgs/leftArrow.svg';
import rightArrow from '../assets/svgs/rightArrow.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';

const MySlider = () => {
    const [portfolioData, setPortfolioData] = useState([]);
    const navigate = useNavigate();
    const swiperRef = useRef(null);
    const wheelTimeout = useRef(null);

    useEffect(() => {
        const getPortfolioData = async () => {
            const response = await axios.get(`${BASE_URL}/portfolio`);
            setPortfolioData(response.data.data);
        };
        getPortfolioData();
    }, []);

    useEffect(() => {
        AOS.init({ duration: 1000, once: true, offset: 50 }); // Initialize AOS with animation duration
    }, []);

    const [isHovered, setIsHovered] = useState(false);

    return (
        <div className="portfolio-slider-container" data-aos="fade-right">
            <Swiper
                slidesPerView={3.3}
                spaceBetween={20}
                centeredSlides={false}
                loop={true}
                autoplay={{ delay: 2000, disableOnInteraction: true }} // Ensures autoplay resumes after interaction
                // navigation={{
                //     nextEl: '.swiper-button-next',
                //     prevEl: '.swiper-button-prev',
                // }}
                grabCursor={true}
                freeMode={true}
                modules={[Mousewheel, Autoplay]}
                onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                    if (swiper?.autoplay) {
                        swiper.autoplay.start(); // Ensure autoplay starts only if available
                    }
                }}
                mousewheel={{
                    forceToAxis: true,  // Prevents unwanted vertical scroll
                    sensitivity: 1,  // Adjust for smoothness
                    thresholdDelta: 40, // Ensures single scroll per event
                }}
                breakpoints={{
                    1024: { slidesPerView: 3.3 },
                    768: { slidesPerView: 2.5 },
                    350: { slidesPerView: 1.3 },
                }}
            >
                {portfolioData.map((image, index) => (
                    <SwiperSlide key={index} className="portfolio-slide">
                        <div className="portfolio-image-container">
                            <img className="image" src={image.banner_path + image.banner_image} alt={`Slide ${index + 1}`} />
                            <button
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                onClick={() => navigate(`/portfolio/${image.id}`)}
                                className="hover-button"
                            >
                                View Project
                                <span className='img'>
                                    {isHovered ? (
                                        <img src={GradientArrowIcon} alt="gradient-icon" />
                                    ) : (
                                        <img src={ArrowIcon} alt="arrow-icon" />
                                    )}
                                </span>
                            </button>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            {/* Custom Navigation Buttons */}
            {/* <div className="swiper-button-prev">
                <img src={leftArrow} alt="left-arrow" />
            </div>
            <div className="swiper-button-next">
                <img src={rightArrow} alt="right-arrow" />
            </div> */}
        </div>
    );
};

export default MySlider;
