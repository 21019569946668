import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import AboutUs from './AboutUs';
import DevelopmentCard from './DevelopmentCard';
import Portfolio from './Portfolio';
import OurClients from './OurClients';
// import banner from '../pngs/banner.mp4'
import Blogs from './Blogs';
import Faq from './Faq';
import CaseStudy from './CaseStudy';
// import ContactUs from './ContactUs';
import Testimonial from './Testimonial';
import Loader from './loader'; // Make sure the loader component exists
import Logo from '../pngs/omkatech-logo.png';
import '../components-css/body.scss';
import Header from './Header';
import axios from 'axios';
import bgVideo from '../../src/pngs/new-bg-video.mp4'
import bgImg from '../pngs/393kb.png'
import bgVideoMobile from '../../src/pngs/mobile-bg-video.mp4'
// import ConsultWithOmkatech from './ConsultWithOmkatech';
import { BASE_URL } from '../config';
// import PrivacyPolicy from '../pages/PrivacyPolicy';

const Body = () => {
  const [aboutUsData, setAboutUsData] = useState(null);
  const [servicesData, setServicesData] = useState(null)
  const [brandsData, setBrandsData] = useState(null)
  // const [faqData, setFaqData] = useState(null)
  const [loading, setLoading] = useState(true);
  const [testimonialData, setTestimonialData] = useState(null)
  const [blogsData, setBlogsData] = useState(null)

  useEffect(() => {
    const fetchHomePageData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/GetHomeData`);
        setAboutUsData(response?.data?.data?.aboutus);
        setServicesData(response?.data?.data?.service)
        setBrandsData(response?.data?.data?.brand)
        // setFaqData(response?.data?.data?.faq)
        setTestimonialData(response?.data?.data?.testimonial)
        setBlogsData(response?.data?.data?.Blog)
        // console.log('response', response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchHomePageData();
  }, []);

  return (
    <div className={`body-main-container ${loading ? 'loading' : ''}`}>
      {loading ? (
        <Loader /> // Display the loader while fetching data
      ) : (
        <>
          <div className="banner-container">
            {/* Background Video */}
            <video
              className="background-video"
              src={bgVideo}
              autoPlay
              muted
              loop
              playsInline
              poster={bgImg}
            >
              Your browser does not support the video tag.
            </video>

            <video
              className="background-video-mobile"
              src={bgVideoMobile}
              autoPlay
              muted
              loop
              playsInline
              poster={bgImg}
            >
              Your browser does not support the video tag.
            </video>

            <Header />
            <motion.div className="body-animation">
              <motion.span
                className="animated-text"
                initial={{ opacity: 0, y: 50 }} // Ensure it starts below
                animate={{ opacity: 1, y: 0 }} // Moves up into position
                transition={{ duration: 1, ease: "easeOut" }}
                style={{ display: "inline-block" }} // Important for Motion animations
              >
                <b>ACCELERATE</b> YOUR
              </motion.span>
              <br />
              <motion.span
                className="animated-text business"
                initial={{
                  clipPath: "polygon(0 100%, 100% 100%, 100% 100%, 0 100%)", // Starts fully hidden
                  opacity: 0,
                  scale: 0.9,
                  background: "linear-gradient(to left, #FFD80B, #FEAA00)",
                  backgroundSize: "200% 100%",
                  color: "#000",
                  y: 50, // Moves from bottom to top
                }}
                animate={{
                  clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)", // Reveals text
                  opacity: 1,
                  scale: 1,
                  backgroundSize: "200% 100%",
                  color: "#000",
                  y: 0,
                }}
                transition={{
                  duration: 1.5,
                  ease: "easeInOut",
                  backgroundPosition: { repeat: Infinity, duration: 2, ease: "linear" },
                }}
              >
                <span className="create"><b>BUSINESS</b></span>
              </motion.span>
              <br />
              <motion.span
                className="animated-text"
                initial={{ opacity: 0, y: 50 }} // Start from below
                animate={{ opacity: 1, y: 0 }} // Move up smoothly
                transition={{ duration: 1, ease: "easeOut", delay: 0.4 }}
                style={{ display: "inline-block" }} // Ensures Motion applies transform correctly
              >
                INTO THE <b>DIGITAL AGE</b>
              </motion.span>

            </motion.div>

          </div>
          <AboutUs />
          <DevelopmentCard servicesData={servicesData} />
          <Portfolio />
          <OurClients brandsData={brandsData} />
          {/* <CaseStudy /> */}
          <Testimonial testimonialData={testimonialData} />
          <Blogs blogsData={blogsData} />
          <Faq />
          {/* <PrivacyPolicy /> */}
        </>
      )}
    </div>

  );
};

export default Body;
