import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'; // Import useParams
import ArrowIcon from '../assets/svgs/Group 3 (6).svg';
import WhiteArrowIcon from '../assets/svgs/white-arrow.svg';
import GradientArrowIcon from '../assets/svgs/Group 3 (5).svg';
import '../components-css/service-page.scss'
import designingImg from '../assets/svgs/our-designing-process.svg'
import designingImg2 from '../assets/svgs/Group 34405.svg'
import designingImg3 from '../assets/svgs/Group 34412.svg'
import toolsUsedImg from '../pngs/Group 105133.png'
import chooseImg from '../pngs/Rectangle 394.png'
import OurClients from '../components/OurClients'
import circleArrow from '../assets/svgs/circle-arrow.svg'
import Faq from '../components/Faq'
import { Link,useNavigate } from "react-router-dom";
import axios from 'axios';
import { BASE_URL } from '../config';
import DOMPurify from "dompurify";

const ServicePage = () => {
      let navigate = useNavigate()
    

    const getPlainText = (html) => {
        if (!html) return "";
            const tempDiv = document.createElement("div");
            tempDiv.innerHTML = html;
            return tempDiv.textContent || tempDiv.innerText || "";
    };
    const { id } = useParams(); // Get the id from the URL
    const [isHovered, setIsHovered] = useState(false);
    const [serviceData, setServiceData] = useState(null)
    const relatedArticlesData = [
        {
            image: circleArrow,
            heading: 'Design Systems Repo',
            description: 'A one-stop place for design system ...'
        },
        {
            image: circleArrow,
            heading: 'Design Systems Repo',
            description: 'A one-stop place for design system ...'
        },
        {
            image: circleArrow,
            heading: 'Design Systems Repo',
            description: 'A one-stop place for design system ...'
        },
    ]
    const designingProcessData = [
        {
            image: designingImg,
            title: 'DISCOVER',
            list: [
                {
                    name: 'User Research',
                },
                {
                    name: 'User Surveys',
                },
                {
                    name: 'User Persona',
                },
                {
                    name: 'User Stories',
                }
            ]
        },
        {
            image: designingImg3,
            title: 'DEFINE',
            list: [
                {
                    name: 'Information Architecture',
                },
                {
                    name: 'Site Maps',
                },
                {
                    name: 'Empathy Mapping',
                },
                {
                    name: 'User Journey Map',
                }
            ]
        },
        {
            image: designingImg2,
            title: 'DELIVER',
            list: [
                {
                    name: 'Paper Prototyping',
                },
                {
                    name: 'High Fidelity Mockups',
                },
                {
                    name: 'Invision Prototype',
                }
            ]
        }
    ]

    const getServicePageData = async () => {
        try {
            let payload = {
                service_id: id
            }
            let response = await axios.post(`${BASE_URL}/servicedetail`, payload)
            setServiceData(response.data.data)
            // console.log('service page response', response)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(()=> {
        getServicePageData()
    }, [id])

    return (
        <div className="parallax-container">
            {/* The fixed background image */}
            <div className="background" style={{ backgroundImage: `url('${serviceData?.file_path}${serviceData?.backgroundimage}')`}}></div>
            {/* White overlay section with text */}
            <div className="white-overlay">
                <div className="content">
                    <h1 style={{ backgroundImage: `url('${serviceData?.file_path}${serviceData?.backgroundimage}')`}}>{serviceData?.title}</h1>
                    <p>{serviceData?.subtitle}</p>
                    <div className="button">
                        <button onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}>
                            <Link className='portfolio-page' to="/contact-us"><span>BOOK A FREE CONSULTATION</span></Link><span className='img'>
                                {isHovered ? (
                                    <img src={GradientArrowIcon} alt="gradient-icon" />
                                ) : (
                                    <img src={ArrowIcon} alt="arrow-icon" />
                                )}
                            </span>
                        </button>
                    </div>
                </div>
            </div>

            {/* Additional scrolling content */}
            <div className="scroll-content">
            </div>
            <div className="our-designing-process">
                <h2>OUR DESIGNING PROCESS</h2>
                <p>We combine our team’s depth of experience in design, our client’s intimate knowledge of the industries they serve and insights uncovered through the discoveries we make.</p>
                <div className="cards">
                    {designingProcessData.map((designing) => {
                        return <div className="card">
                            <img src={designing.image} alt="" />
                            <h4>{designing.title}</h4>
                            <ul>
                                {designing.list.map((list) => {
                                    return <li>{list.name}</li>
                                })}
                            </ul>
                        </div>
                    })}
                </div>
            </div>
            <div className="tools-used">
                <h2>Tools We Use for Ideation and Product Design</h2>
                <div className='tools-icons'>
                    {serviceData?.tool_and_technology?.map((tool_and_technology, index) => (
                        <div className='icon'>
                            <img src={ `${tool_and_technology.image_path}${tool_and_technology.image} `} alt="" />
                        </div>
                    ))}
                </div>
                

            </div>
            <div className="why-choose-omkatech">
            <h3 className='responsive-why-choose-title'>{serviceData?.why_choose_title}</h3>
                <div className="left-content">
                    <h3 className='why-choose-title'>{serviceData?.why_choose_title}</h3>
                    <div dangerouslySetInnerHTML={{ __html: serviceData?.why_choose_description }} />
                </div>
                <div className="right-img">
                    <img src={  `${serviceData?.file_path}${serviceData?.why_choose_image}`} alt="" />
                </div>
            </div>
            <OurClients checkcolor="color" />
            <div className="realted-articles">
                <h2>RELATED ARTICLES</h2>
                <div className="slide-images">
                
                    {serviceData?.blogs.map((articlesData) => {
                        return <div onClick={()=> {navigate(`/blog/${articlesData.id}`)}} className="slide" style={{ backgroundImage: `url('${articlesData?.file_path}${articlesData?.image}')`}}>
                            {/* <img src={`${articlesData.file_path}${articlesData.image} `} alt="" /> */}
                            <div className="heading">
                                <h5>{articlesData?.title}</h5>

                                <p>{getPlainText(articlesData?.description).slice(0, 40) + "..."}</p>
                            </div>
                        </div>
                    })}
                </div>
            </div>
            
            <Faq service_id={id} />

            <div className="bg">
            <div className="connect-with-expert">
                <h2>Product Design and Ideation Services</h2>
                <p>We ideate, design, and deliver seamless experiences by bridging the gap between your idea and the product </p>
                <div className="button">
                    <button onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}>
                        <Link to="/contact-us" className='portfolio-page'><span>START YOUR PROJECT TODAY</span></Link><span className='img'>
                            {isHovered ? (
                                <img src={GradientArrowIcon} alt="gradient-icon" />
                            ) : (
                                <img src={WhiteArrowIcon} alt="arrow-icon" />
                            )}
                        </span>
                    </button>
                </div>
            </div>
            </div>
        </div>
    )
}

export default ServicePage
