import React from 'react'
import { useState, useRef, useEffect } from 'react'
import '../components-css/portfolio-page.scss'
import ArrowIcon from '../assets/svgs/Group 3.svg';
import GradientArrowIcon from '../assets/svgs/Group 3 (5).svg'
import Project1 from '../pngs/Mask group (1).png'
import CaseStudy from '../components/CaseStudy';
import caseStudyImage from "../pngs/Rectangle 54.png";
import '../components-css/case-study-page.scss'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../config';

const CaseStudyPage = () => {
    const navigate = useNavigate();
    const containerRef = useRef(null);
    const contentRef = useRef(null);
    const [isHovered, setIsHovered] = useState(false);
    const [contentData, setCasestudyData] = useState([]);
    
    useEffect(()=> {
      getCasestudyData()
    }, [])
    
    const getCasestudyData = async () => {
        try {
          let response = await axios.get(`${BASE_URL}/casestudy`)
          setCasestudyData(response.data.data)
        //   console.log('respone about us page', response.data.data)
        } catch (error) {
          console.log(error)
        }
      }
    


    return (
        <>
            <div className='portfolio-page-container'>
                <div className="portfolio-heading">
                    <h1>CASE STUDY</h1>
                    <p>Explore how our innovative solutions have driven success for businesses across industries.</p>
                </div>
            </div>
            <div className="case-study-container d-flex">
                {contentData?.map((contentData, index) => (
                    <div className={`case-study-inner ${index % 2 === 0 ? 'even' : 'odd'}`}>
                        <div className="content-container left">
                            <h2>{contentData?.title}</h2>
                            <span className='case-study-subtitle'>{contentData?.sub_title}</span>
                            
                            <div className="app-details">
                                <div className="results">
                                    <h2>Technology</h2>
                                    <span>{contentData?.technology}</span>
                                </div>
                                <div className="users">
                                    <h2>Industry</h2>
                                    <span>{contentData?.industry}</span>
                                </div>
                            </div>
                            <div className="button">
                                <button onClick={() => navigate(`/case-study/${contentData?.id}`)} className="btn-bg" onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}>
                                    <span>VIEW CASE STUDY</span> <span className='img'>
                                        {isHovered ? (
                                            <img src={GradientArrowIcon} alt="gradient-icon" />
                                        ) : (
                                            <img src={ArrowIcon} alt="arrow-icon" />
                                        )}
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="image-container right">
                            <img src={ `${contentData?.home_page_image_path}${contentData?.home_page_image}` } alt="image" />
                        </div>
                        <div className="button show-in-mobile">
                                <button onClick={() => navigate(`/case-study/${contentData?.id}`)} className="btn-bg" onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}>
                                    <span>VIEW CASE STUDY</span> <span className='img'>
                                        {isHovered ? (
                                            <img src={GradientArrowIcon} alt="gradient-icon" />
                                        ) : (
                                            <img src={ArrowIcon} alt="arrow-icon" />
                                        )}
                                    </span>
                                </button>
                            </div>
                    </div>
                ))}
            </div>
        </>
    );
}

export default CaseStudyPage