import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import Img from '../assets/svgs/menu-bar.svg';
import '../components-css/blogs.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowIcon from '../assets/svgs/Group 3.svg';
import GradientArrowIcon from '../assets/svgs/Group 3 (5).svg';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { BASE_URL } from '../config';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Blogs = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [blogsData, setBlogsData] = useState(props?.blogsData || null);
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const rightSliderRef = useRef(null); // Reference to right slider section

  // Format the date for display
  const formatDate = (isoString) => {
    if (!isoString) return "";
    const date = new Date(isoString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  };

  useEffect(() => {
    if (window.innerWidth < 768) {
      AOS.init({ disable: 'mobile' });
    } else {
      AOS.init({ duration: 1000, once: true, offset: 50 });
    }
  }, []);

  // Slider settings
  const settings = {
    infinite: true,
    speed: 2000,
    slidesToShow: 2,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    arrows: false,
    cssEase: 'linear',
    pauseOnHover: true,
    touchMove: true,
    swipe: true,
    draggable: true,
  };

  useEffect(() => {
    if (!blogsData) {
      axios.get(`${BASE_URL}/GetBlogs`)
        .then((response) => setBlogsData(response.data.data))
        .catch((error) => console.error('Error fetching blogs:', error));
    }
  }, [blogsData]);

  // Ensure data exists before accessing properties
  const firstBlog = blogsData?.first_blog?.[0] || {};
  const formattedDate = formatDate(firstBlog.created_at);

  // Handle Mouse Wheel Scrolling for the slider
  const handleWheel = (e) => {
    if (sliderRef.current && rightSliderRef.current.contains(e.target)) {
      e.preventDefault(); // Prevents the whole page from scrolling
      e.stopPropagation(); // Stops event from propagating to other elements

      if (e.deltaY > 0) {
        sliderRef.current.slickNext();
      } else {
        sliderRef.current.slickPrev();
      }
    }
  };

  useEffect(() => {
    const sliderElement = rightSliderRef.current;

    if (sliderElement) {
      sliderElement.addEventListener("wheel", handleWheel, { passive: false });
    }

    return () => {
      if (sliderElement) {
        sliderElement.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  return (
    <div className="blogs-main-container">
      {/* Left Section */}
      <div className="left-section" onClick={() => navigate(`/blog/${firstBlog.id}`)} data-aos="fade-right">
        <p>{formattedDate}</p>
        <h2>{firstBlog.title}</h2>
        <img className='image' src={firstBlog.file_path + firstBlog.thumbnail} alt="Blog Thumbnail" />
        <div className="button">
          <button
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <Link className='portfolio-page'><span>BLOG</span></Link>
            <span className='img'>
              <img src={isHovered ? GradientArrowIcon : ArrowIcon} alt="Arrow Icon" />
            </span>
          </button>
        </div>
      </div>

      {/* Right Slider Section */}
      <div className="right-slider-section" ref={rightSliderRef} data-aos="fade-left">
        <Slider ref={sliderRef} {...settings}>
          {blogsData?.blog_list?.map((blog) => (
            <div onClick={() => navigate(`/blog/${blog.id}`)} className='slide' key={blog.id}>
              <img className='blog-image' src={blog.file_path + blog.thumbnail} alt="Blog Image" />
              <div className="heading">
                <div className="img">
                  <img src={Img} alt="Menu Icon" />
                </div>
                <h3>{blog.title}</h3>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Blogs;
