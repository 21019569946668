import React, { useState, useEffect } from 'react'
import ArrowIcon from '../assets/svgs/Group 3.svg';
import GradientArrowIcon from '../assets/svgs/Group 3 (5).svg';
import { Link } from "react-router-dom";
import processImg from '../assets/svgs/process-card-img.svg'
import ONBOARDINGImg from '../assets/svgs/ON-BOARDING.svg'
import INTERVIEWINGImg from '../assets/svgs/INTERVIEWING.svg'
import shortListingImg from '../assets/svgs/short-Listing.svg'
import PROFILESCREENINGImg from '../assets/svgs/PROFILE-SCREENING.svg'
import SENDUSYOURENQUIRYImg from '../assets/svgs/SEND-US-YOUR-ENQUIRY.svg'
import img1 from '../pngs/Ellipse 23.png'
import img2 from '../pngs/Ellipse 25.png'
import img3 from '../pngs/Group 443.png'
import img4 from '../pngs/Ellipse 28.png'
import img5 from '../pngs/Ellipse 29.png'
import '../components-css/hire-resources.scss'
import technologyImg from '../assets/svgs/technology-img.svg'
import axios from 'axios';
import { BASE_URL } from '../config';
import { ToastContainer, toast } from 'react-toastify';

const HireResource = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [profile, setProfile] = useState('');
  const [message, setMessage] = useState('');


  const [hiredata, setHiredata] = useState(null);
  const [technologyDataa, setTechnologyData] = useState(null);
  const [HireData, setHireData] = useState(null);
  const [hireOmkatechDevData, sethireOmkatechDevData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    getHireData()
  }, [])

  const getHireData = async () => {
    try {
      let response = await axios.get(`${BASE_URL}/hire-resources`)
      // setHiredata(response.data.data)
      setTechnologyData(response.data.data.tool_and_technology)
      setHireData(response.data.data.data)
      sethireOmkatechDevData(response.data.data.whyhire)


    } catch (error) {
      console.log(error)
    }
  }

  const postContact = async () => {
    if (!name || !email || !phone || !profile || !message) {
      toast.error("All fields are required.", { position: toast.POSITION.TOP_RIGHT });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Invalid email address!", { position: toast.POSITION.TOP_RIGHT });
      return;
    }

    const phoneRegex = /^[0-9]{10,15}$/; // Ensures only digits and at least 10 characters
    if (!phoneRegex.test(phone)) {
      toast.error("Phone number must be at least 10 digits and contain only numbers!", { position: toast.POSITION.TOP_RIGHT });
      return;
    }

    let payload = {
      name,
      email,
      number: "+" + phone,
      profile,
      message
    };

    try {
      let response = await axios.post(`${BASE_URL}/hiring-requirements`, payload);

      // Reset form fields
      setName("");
      setEmail("");
      setPhone("");
      setProfile("");
      setMessage("");

      toast.success(response?.data?.message || "Your message has been sent successfully!", { position: toast.POSITION.TOP_RIGHT });
    } catch (error) {
      toast.error(error?.response?.data?.message || "Failed to send your message. Please try again.", { position: toast.POSITION.TOP_RIGHT });
    }
  };



  const [isHovered, setIsHovered] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('all');

  const filterTechnologyData = (category) => {
    if (category === 'all') return technologyDataa;
    return technologyDataa.filter(technology => technology.technologie_for === category);
  }

  return (
    <div className="hire-resources-container">
      <div className="our-testimonial-banner">
        <div className="testimonial-left-content">
          <h1>{HireData?.banner_title}</h1>
          <p>{HireData?.banner_subtitle}</p>
        </div>
        <div className="testimonial-right-content">
          <img className="testimonial-image" src={`${HireData?.banner_background_image_path}${HireData?.banner_background_image}`} alt="img-1" />
        </div>
      </div>
      <div className="hire-developers">
        <h3>{HireData?.title}</h3>
        <p>{HireData?.description}</p>
      </div>
      <div className="hire-form">
        <h2>HIRE A RESOURCE TODAY!</h2>
        <p>Submit your details and we will get back to you.</p>
        <div className="form">
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <div className="inner-form-bg">
            <input type="text" placeholder='Full name' value={name} onChange={(e) => setName(e.target.value)} />
            <input type="text" placeholder='Your email address' value={email} onChange={(e) => setEmail(e.target.value)} />
            <input type="text" placeholder='Phone' value={phone} onChange={(e) => {
              const inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
              setPhone(inputValue);
            }} />
            <select value={profile} onChange={(e) => setProfile(e.target.value)}>
              <option value="">Profile</option>
              <option value="Backend Developer">Backend Developer</option>
              <option value="Frontend Developer">Frontend Developer</option>
              <option value="DevOps">DevOps</option>
              <option value="Mobile">Mobile</option>
              <option value="QA">QA</option>
              <option value="Ecommerce">E-commerce</option>
            </select>
            <input type="textarea" placeholder='Message' value={message} onChange={(e) => setMessage(e.target.value)} />
            <div className="button">
              <button
                onClick={postContact}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}>
                <Link className='portfolio-page'>
                  <span>Submit Your Hiring Requirements</span>
                </Link>
                <span className='img'>
                  {isHovered ? (
                    <img src={GradientArrowIcon} alt="gradient-icon" />
                  ) : (
                    <img src={ArrowIcon} alt="arrow-icon" />
                  )}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="our-hiring-process-section">
        <h2>5 EASY STEPS TO HIRE RESOURCES</h2>
        <p>We offer a hassle-free process for hiring resources. You can onboard the best resources.</p>
        <div className="process-cards">
          <div className="card">
            <img src={SENDUSYOURENQUIRYImg} alt="" />
            <p>SEND US YOUR ENQUIRY</p>
          </div>
          <div className="card">
            <img src={PROFILESCREENINGImg} alt="" />
            <p>PROFILE SCREENING</p>
          </div>
          <div className="card">
            <img src={shortListingImg} alt="" />
            <p>SHORT LISTING</p>
          </div>
          <div className="card">
            <img src={INTERVIEWINGImg} alt="" />
            <p>INTERVIEWING</p>
          </div>
          <div className="card">
            <img src={ONBOARDINGImg} alt="" />
            <p>ON-BOARDING</p>
          </div>
        </div>
      </div>
      <div className="our-expertise">
        <h2>Our Expertise - What Makes Us the best destination to Hire Resources </h2>
        <p>At Omka Tech, we don’t just provide developers; we build long-term partnerships to drive your success. Let us help you create, innovate,
          and scale your business with unmatched talent and expertise.</p>
        <div className="filter-tabs">
          <button className={selectedCategory === 'all' ? 'active' : ''} onClick={() => setSelectedCategory('all')}>All</button>
          <button className={selectedCategory === 'backend' ? 'active' : ''} onClick={() => setSelectedCategory('backend')}>Backend Developer</button>
          <button className={selectedCategory === 'frontend' ? 'active' : ''} onClick={() => setSelectedCategory('frontend')}>Frontend Developer</button>
          <button className={selectedCategory === 'devops' ? 'active' : ''} onClick={() => setSelectedCategory('devops')}>DevOps</button>
          <button className={selectedCategory === 'mobile' ? 'active' : ''} onClick={() => setSelectedCategory('mobile')}>Mobile</button>
          <button className={selectedCategory === 'QA' ? 'active' : ''} onClick={() => setSelectedCategory('QA')}>QA</button>
          <button className={selectedCategory === 'ecommerce' ? 'active' : ''} onClick={() => setSelectedCategory('ecommerce')}>E-commerce</button>
        </div>
        <div className="technology-cards">
          {filterTechnologyData(selectedCategory)?.map((technology, index) => (
            <div className="card" key={index}>
              <div className='tech-logo'>
                <img src={`${technology?.image_path}${technology?.image} `} alt="" />
              </div>
              <span>{technology?.tool_and_technologie_name}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="hire-dev-omkatech">
        <h2>WHY HIRE DEVELOPERS FROM OMKA TECH!</h2>
        <p>Experience top-tier development with Omka Tech’s talented team, driving your projects toward exceptional outcomes and success.</p>
        <div className="cards">
          {hireOmkatechDevData?.map((omkatechDev) => {
            return <div className="card">
              <img src={`${omkatechDev.icon_path}${omkatechDev.icon}`} alt="" />
              <h5>{omkatechDev.title}</h5>
              <p>{omkatechDev.description}</p>
            </div>
          })}
        </div>
      </div>
    </div>
  )
}

export default HireResource;
