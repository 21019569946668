import React, { useState, useRef, useEffect } from 'react';
import comma from '../pngs/comma.png';
import '../components-css/testimonial.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Mousewheel, Autoplay } from 'swiper/modules';
import PopupYoutube from './Popupyoutube';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Testimonial = (prop) => {
  const [testimonialData] = useState(prop.testimonialData);
  const [showPopup, setShowPopup] = useState(false);
  const [link, setLink] = useState('');
  const swiperRef = useRef(null);

  useEffect(() => {
    AOS.init({ duration: 1000, once: true, offset: 50 }); // Initialize AOS with animation duration
}, []);

  // useEffect(() => {
  //   if (swiperRef.current && swiperRef.current.swiper) {
  //     swiperRef.current.swiper.autoplay.start();
  //   }
  // }, []);

  const openPopup = (videoLink) => {
    setLink(videoLink);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setLink('');
  };

  return (
    <div className="testimonial-main-container">
      <h2>TESTIMONIAL</h2>
      {showPopup && <PopupYoutube link={link} onClose={handleClosePopup} />}
      <Swiper
      data-aos="fade-top"
        ref={swiperRef}
        slidesPerView={1}
        spaceBetween={30}
        autoplay={{
          delay: 7000,
          disableOnInteraction: false,
        }}
        mousewheel={{
          forceToAxis: true,
          sensitivity: 0.5,
          thresholdDelta: 50,
          releaseOnEdges: true
      }}
      navigation={false}
        modules={[Mousewheel, Autoplay]}
        className="testimonial-swiper"
        onSwiper={(swiper) => (swiperRef.current = { swiper })}
      >
        {testimonialData?.map((client, index) => (
          <SwiperSlide key={index}>
            <div className="clients-review">
              <div className="content">
                <div className='feedback-box'>
                  <img src={comma} className='comma' alt="comma-icon" />
                  <div className='client-feedback'>{client.review}</div>
                </div>

                <div className="client-content">
                  <div className="client-detail">
                    <img src={client.file_path + client.image} className='client-image' alt="client-image" />
                    <div className="about-client">
                      <h3>{client.client_name}</h3>
                      <p>{client.client_designation} <span>{client.client_company}</span></p>
                    </div>
                  </div>
                  {client.youtube_video_link && (
                    <div className="video">
                      <div className='youtube-icon'>
                        <img
                          onClick={() => openPopup(client.youtube_video_link)}
                          src={`https://img.youtube.com/vi/${client.youtube_video_id}/maxresdefault.jpg`}
                          className='youtube-image'
                          alt="youtube-thumbnail"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Testimonial;
